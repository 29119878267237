import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";

export function getApiDomain() {
    const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:5000`;
    return apiUrl;
}

export function getWebsiteDomain() {
    const websiteUrl = process.env.REACT_APP_WEBSITE_URL || `http://localhost:3000`;
    return websiteUrl;
}

export const SuperTokensConfig = {
    appInfo: {
        appName: "Homelab Architecture",
        apiDomain: getApiDomain(),
        websiteDomain: getWebsiteDomain(),
    },
    style: `
        [data-supertokens~=authPage] [data-supertokens~=headerSubtitle] {
            display: none;
        }
    `,
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [EmailPassword.init(), Session.init()],
};

export const recipeDetails = {
    docsLink: "https://supertokens.com/docs/emailpassword/introduction",
};

export const PreBuiltUIList = [EmailPasswordPreBuiltUI];

export const ComponentWrapper = (props: { children: JSX.Element }): JSX.Element => {
    return props.children;
};
