// api/api.tsx
import {getApiDomain} from "../config";

const API_BASE_URL = getApiDomain(); // Set your base URL here if necessary

async function getRequest(endpoint: string) {
    try {
        const response = await fetch(`${API_BASE_URL}${endpoint}`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data.message; // Return the JSON data
    } catch (error) {
        console.error(`Error fetching data from ${endpoint}:`, error);
        throw error; // Rethrow the error for handling in the calling function
    }
}

export {getRequest};
