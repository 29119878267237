import { useSessionContext } from "supertokens-auth-react/recipe/session";
import "../styles/Home.css";
import DashboardView from "./DashboardView";

export default function Home() {
    const sessionContext = useSessionContext();

    if (sessionContext.loading === true) {
        return null;
    }

    return (
        <div className="fill" id="home-container">
            <DashboardView userId={sessionContext.userId} />
        </div>
    );
}
