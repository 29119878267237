import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRequest } from '../api/api'; // Assuming getRequest is your function to handle API calls
import "../styles/ServiceDetails.css"; // Import relevant CSS

interface IMessage {
    container_sha_id?: string; // Made optional
    created?: string;          // Made optional
    hostname?: string;        // Made optional
    image?: string;           // Made optional
    internal_ip?: string;     // New optional field for internal IP
    mounts?: Array<{ destination: string; source: string }>; // Made optional
    name: string;
    networks?: string[];      // Made optional
    ports?: number[];         // Made optional
    state?: string;           // Made optional
}

const ServiceDetails = () => {
    const { serviceName } = useParams<{ serviceName: string }>();
    const [serviceData, setServiceData] = useState<IMessage | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchServiceData() {
            try {
                const response = await getRequest(`/service/${serviceName}`);
                if (response) {
                    setServiceData(response);
                } else {
                    throw new Error('No data found');
                }
            } catch (error) {
                console.error("Error fetching service data:", error);
            } finally {
                setLoading(false);
            }
        }
        fetchServiceData();
    }, [serviceName]);

    if (loading) {
        return <div className="loading">Loading service details...</div>;
    }

    if (!serviceData) {
        return <div className="error">No service data available.</div>;
    }

    return (
        <div className="service-details-container">
            <h2 className="service-title">Service Details</h2>
            <div className="service-card">
                <h3>{serviceData.name}</h3>
                <div className="service-info">
                    {serviceData.hostname && (
                        <div className="info-item">
                            <strong>Hostname:</strong>
                            <a href={`http://${serviceData.hostname}`} target="_blank" rel="noopener noreferrer" className="hostname-link">
                                {serviceData.hostname}
                            </a>
                        </div>
                    )}
                    {serviceData.image && (
                        <div className="info-item">
                            <strong>Image:</strong> {serviceData.image}
                        </div>
                    )}
                    {serviceData.container_sha_id && (
                        <div className="info-item">
                            <strong>Container SHA ID:</strong> {serviceData.container_sha_id}
                        </div>
                    )}
                    {serviceData.state && (
                        <div className="info-item">
                            <strong>State:</strong>
                            <span className={`state-indicator ${serviceData.state.toLowerCase()}`}>
                                {serviceData.state}
                            </span>
                        </div>
                    )}
                    {serviceData.internal_ip && ( // New section for internal IP
                        <div className="info-item">
                            <strong>Internal IP:</strong> {serviceData.internal_ip}
                        </div>
                    )}
                </div>
            </div>

            <div className="service-section">
                <h4>Mounts</h4>
                {serviceData.mounts && serviceData.mounts.length > 0 ? (
                    <ul className="mounts-list">
                        {serviceData.mounts.map((mount, index) => (
                            <li key={index} className="mount-item">
                                <strong>Source:</strong> {mount.source} → <strong>Destination:</strong> {mount.destination}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No mounts available.</p>
                )}
            </div>

            <div className="service-section">
                <h4>Networks</h4>
                {serviceData.networks && serviceData.networks.length > 0 ? (
                    <p>{serviceData.networks.join(", ")}</p>
                ) : (
                    <p>No networks available.</p>
                )}
            </div>

            <div className="service-section">
                <h4>Ports</h4>
                {serviceData.ports && serviceData.ports.length > 0 ? (
                    <p>{serviceData.ports.join(", ")}</p>
                ) : (
                    <p>No ports available.</p>
                )}
            </div>
        </div>
    );
};

export default ServiceDetails;
