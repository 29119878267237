import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import "../styles/Dashboard.css";
import { getRequest } from '../api/api';
import {getApiDomain} from "../config";

interface IMessage {
    exposed_ports: number[];
    image_names: string[];
    networks: string[];
    service_names: string[];
    stack_summaries: { [key: string]: number };
    total_exposed_ports: number;
    total_images: number;
    total_networks: number;
    total_running: number;
    total_services: number;
    total_stacks: number;
    total_stopped: number;
}

export default function DashboardView(props: { userId: string }) {
    const [dashboardData, setDashboardData] = useState<IMessage | null>(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchDashboardData() {
            try {
                const response = await getRequest("/home");
                setDashboardData(response);
            } catch (error) {
                console.error("Error fetching dashboard data:", error);
            } finally {
                setLoading(false);
            }
        }
        fetchDashboardData();
    }, []);

    async function logoutClicked() {
        await signOut();
        navigate("/auth");
    }

    if (loading) {
        return <div className="loading">Loading your dashboard data...</div>;
    }

    if (!dashboardData) {
        return <div className="error">No dashboard data available.</div>;
    }

    return (
        <div className="dashboard-container">
            <header className="dashboard-header">
                <h1>Homelab</h1>
                <button onClick={logoutClicked} className="logout-button">Sign Out</button>
            </header>

            <main className="dashboard-main">
                <section className="dashboard-summary">
                    <h3>Summary</h3>
                    <div className="card-container">
                        {Object.entries(dashboardData).map(([key, value]) => {
                            if (key.startsWith("total_")) { // Only display keys starting with "total_"
                                return (
                                    <div className="card" key={key}>
                                        <h4>{key.replace(/_/g, ' ').replace('total ', 'Total ')}</h4>
                                        <p>{value}</p>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                </section>

                <section className="service-names">
                    <h3>Service Names</h3>
                    <div className="service-cards-container">
                        {dashboardData.service_names.map((service) => (
                            <div
                                key={service}
                                className="service-card"
                                onClick={() => navigate(`/service/${service}`)}
                            >
                                <h4>{service}</h4>
                            </div>
                        ))}
                    </div>
                </section>

                {/* New Section for Homelab Architecture Image */}
                <section className="homelab-architecture">
                    <h3>Homelab Architecture</h3>
                    <img
                        src={`${getApiDomain()}/static/images/homelab_architecture.png`}
                        alt="Homelab Architecture"
                        className="architecture-image"
                    />
                </section>
            </main>

            <footer className="dashboard-footer">
                <p>© 2024 Your Company Name</p>
            </footer>
        </div>
    );
}
